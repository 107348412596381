import './dialogBox.less';

import OverlayScrollbars from 'overlayscrollbars';

/* ----- dialog box 自訂對話框 ----- */
// 只設定基本 HTML 結構，不含 event handler
// CSS 樣式在 dialogbox.less 中
export class dialogBox {

    constructor(id, hint = false, scroll = true, hintStyle = null, maxWidth = null){

        this.id = id; // 設定 dialog box 的 id

        this.hint = hint; // 是否顯示 <div class="hint">

        // 指定不同樣式的提示文字區塊 (className)
        // 目前可用的值為：fullwidth，提示區塊為全寬度淺灰底色，可參考 inventoryAlert
        this.hintStyle = hintStyle;

        // 內容區的最大寬度，輸入包含單位的字串，例如 '800px', '90vw'
        // null 表示用 CSS 中的預設值
        this.maxWidth = maxWidth;

        this.useScroll = scroll;

        this.box = document.createElement('div');

        this.scrollbar = null;

        this.init();
    }

    init(){

        const lang_box = window.langTxt.dialogBox;

        this.box.id = this.id;
        this.box.className = 'dialogBox'; // 初始狀態是隱藏的

        // hint 區塊
        const hintSection = (this.hint)? '<div class="hint"></div>' : '';

        // hint 區塊的樣式，因為 title 的樣式也需要調整，所以加上 dialog-container 上
        const extraStyle = (this.hintStyle)? ` ${this.hintStyle}` : '';

        // 不使用捲軸，需要將 content 設定為 overflow:visible
        const noScroll = (this.useScroll === false)? ' no-scroll' : '';

        let inlineStyle = '';

        // 內容區的最大寬度
        if(this.maxWidth){
            inlineStyle = ` style="max-width:${this.maxWidth};"`;
        }

        this.box.innerHTML =
        `<div class="dialog-overlay"></div>
        <div class="dialog-container${extraStyle}"${inlineStyle}>

            <h2 class="title"></h2>

            ${hintSection}

            <div class="content os-host-flexbox${noScroll}">
                <div class="innerwpr"></div>
            </div>

            <div class="feedback"></div>

            <div class="btnwpr">
                <div class="btn cancel" id="${this.id}cancel">
                    <svg><use xlink:="" href="/assets/sprites/sprites_solid.svg#times"></use></svg>${lang_box.btn.cancel}
                </div>
                <div class="btn confirm" id="${this.id}confirm">
                    <svg><use xlink:="" href="/assets/sprites/sprites_solid.svg#check"></use></svg>${lang_box.btn.confirm}
                </div>
            </div>

        </div>`;

        document.body.appendChild(this.box);

        if(this.useScroll === true){
            // 實體化自訂捲軸
            this.scrollbar =
            OverlayScrollbars(this.box.querySelector('.content'), {
                overflowBehavior: {
                    x: 'hidden'
                }
            });

            this.scrollbar.sleep();  // 捲軸休眠
        }
    }

    /* ----- getter: 取得 dialogBox 的 DOM container ----- */
    // ex: const boxContainer = sampleDialogBox.container
    get container(){
        return this.box;
    }

    /* ----- 開啟 dialogBox ----- */
    // customClass 指定額外的 className
    open(customClass = null){
        document.body.classList.remove('loading');

        if(customClass){
            this.box.className = `dialogBox show ${customClass}`;
        } else {
            this.box.classList.add('show');
        }

        if(this.useScroll === true){
            this.scrollbar.update(); // 更新捲軸
        }
    }

    /* ----- 關閉 dialogBox ----- */
    close(clear = true){
        // clear: 是否清空內容，預設清空
        // removeBtn: 清空按鈕區塊
        this.box.classList.remove('show');

        if(clear){
            setTimeout(() => {
                this.box.querySelector('.innerwpr').innerHTML = ''; // 清空內容
                this.clear_feedback();  // 清空 feedback 訊息
                if(this.useScroll === true){
                    this.scrollbar.sleep(); // 捲軸休眠
                }
            },400);
        }

    }

    /* ----- 設定標題 (innerHTML) ----- */
    set_title(html){
        this.box.querySelector('.title').innerHTML = html;
    }

    /* ----- 設定提示文字或額外內容 (innerHTML) ----- */
    set_hint(html){
        this.box.querySelector('.hint').innerHTML = html;
    }

    /* ----- 設定內容區塊 (innerHTML) ----- */
    set_content(html){
        this.box.querySelector('.innerwpr').innerHTML = html;
    }

    /* ----- 設定 feedback 訊息 (innerHTML) ----- */
    set_feedback(html, status = null){
        /*
        status 可以帶入字串，代表不同的狀態，null 的話顯示預設樣式
        可以使用：'fail' 'warning' 'success'
        */
        let extraClass = '';
        if(status){
            extraClass = ` ${status}`;
        }

        this.box.querySelector('.feedback').innerHTML =
            `<div class="feedback-content${extraClass}">${html}</div>`;
    }

    // 清除備註訊息
    clear_feedback(){
        this.box.querySelector('.feedback').innerHTML = '';
    }


    /* ----- 設定自訂按鈕區塊 (innerHTML) ----- */
    set_btnwpr(html){
        this.box.querySelector('.btnwpr').innerHTML = html;
    }

    /* ----- 在 confirm 按鈕加上 data-key 值 ----- */
    // 可多次執行，指定不同的 data 值
    set_confirmDataset(key, value){
        this.box.querySelector('.confirm').dataset[key] = value;
    }

    /* ----- 傳入參數來呼叫 overlayscrollbars 的 scroll 方法 ----- */
    callScroll(coordinates, duration){
        if(this.useScroll === true){
            this.scrollbar.scroll(coordinates, duration);
        }
    }

    // ----- 在使用預設按鈕的前提下，設定 confirm 按鈕的文字  ----- //
    set_confirmBtnTxt(text){
        this.box.querySelector('.confirm').textContent = text;
    }

    // ----- 在使用預設按鈕的前提下，設定 cancel 按鈕的文字  ----- //
    set_cancelBtnTxt(text){
        this.box.querySelector('.cancel').textContent = text;
    }
}