import './deviceUsageReport.less';

import {
    sendApiRequest,
    globalErrorHandler,
    getManagersMenu
} from '../../js/api';

import {dialogBox} from '../dialogBox/dialogBox';
import {monthPicker} from '../monthPicker/monthPicker';

// 不包含經銷客戶的 comPicker
// import {comPicker} from './comPicker';

// 包含經銷客戶的 comPicker (與授權碼報表共用)
import {comPicker} from '../licenseUsageReport/comPicker';

import _orderBy from 'lodash/orderBy';

import {showFeedbackMsg} from '../../js/helper/feedbackMsg';

// table 欄位設定
import {tableMap} from '../../setting/tableMap/deviceUsageReport';

// 匯出 xlsx
import {exportXlsx} from '../../js/helper/exportXlsx';

import {sideInfoBox} from '../sideInfoBox/sideInfoBox';

import {parseDatetime} from '../../js/helper/helper';

// const dayjs = require('dayjs');

export class deviceUsageReport {

    constructor(btnContainer, roleCode){

        if(!btnContainer){
            return;
        }

        // 插入觸發按鈕的 dom
        this.btnContainer = btnContainer;

        // 權限代碼 (小寫) ex 'cem'
        this.roleCode = roleCode;

        // 需要取得 managersMenu 來建立公司列表
        this.managersMenu = null;

        // dialogBox
        this.box = null;

        // box 中的內容區塊 DOM
        this.boxContent = null;

        // 公司選擇器
        this.durComPicker = null;

        // 年月選擇器
        this.durMonthPicker = null;

        // 儲存報告資料用來匯出，包含 summary 與 records
        this.reportData = {};

        // 顯示查詢裝置轉移紀錄的元件 (device transfer log)
        this.dtlBox = null;

        // 裝置狀態名詞對照
        this.lang_active = window.langTxt.fieldParse.active;
        // 欄位名稱對照
        this.uiTxt = window.langTxt.deviceUsageReport;

        this.init();
        this.events();
    }

    async init(){

        // 加入觸發按鈕
        this.addTriggerBtn();

        // 建立 dialogBox
        this.box = new dialogBox('deviceUsageReportBox', false, false);

        // 初始化 dialogBox 內容
        this.initBoxContent();

        // 顯示查詢裝置轉移紀錄的元件
        this.dtlBox = new sideInfoBox('deviceTransferLogBox');
    }

    events(){

        // trigger events
        document.querySelector('#deviceUsageReportTrigger')
        .addEventListener('click', (event) => {
            this.box.open();
        });


        // box events
        const boxContainer = this.box.container;
        boxContainer.addEventListener('click', (event) => {

            // 關閉，關閉時不清空內容
            if(event.target.id === 'closeDeviceUsageReportBox'){
                this.box.close(false);
            }

            // 送出查詢
            if(event.target.id === 'durQuerySubmit'){
                this.submitDurQuery();
            }

            // 匯出報表
            if(event.target.id === 'exportDeviceUsageReportBox'){
                this.exportReportData();
            }

            // 查詢裝置轉移紀錄
            if(event.target.classList.contains('btn-translog')){
                const dataset = event.target.dataset;
                this.queryTransferLog(dataset.sn, dataset.model);
            }

        });

    }

    /* ----- 加入觸發按鈕 ----- */
    addTriggerBtn(){

        const btn = document.createElement('div');
        btn.id = 'deviceUsageReportTrigger';
        btn.className = 'btn-control';
        btn.innerHTML =
        `<svg>
            <use xlink:href="/assets/sprites/sprites_solid.svg#poll-h"></use>
        </svg>${this.uiTxt.ui.trigger}`;

        this.btnContainer.appendChild(btn);
    }

    /* ----- 初始化 dialogBox 內容 ----- */
    async initBoxContent(){

        const boxDom = this.box.container;
        this.boxContent = boxDom.querySelector('.innerwpr');

        boxDom.classList.add('no-data');

        document.body.classList.add('loading');

        // get managersMenu data
        this.managersMenu = await getManagersMenu();

        // console.log(this.managersMenu);
        // console.log(window.managersMenu);

        const titleHtml =
        `<svg class="title-icon">
            <use xlink:href="/assets/sprites/sprites_solid.svg#poll-h"></use>
        </svg>
        <p>裝置月報表</p>
        <div class="control-menu">
            <div id="durComPicker" class="picker"></div>
            <div id="durMonthPicker" class="picker"></div>
            <div id="durQuerySubmit">開始查詢</div>
        </div>`;

        this.box.set_title(titleHtml);

        // 設定按鈕，關閉與匯出
        const boxBtn =
        `<div class="btn" id="closeDeviceUsageReportBox">關閉</div>
        <div class="btn white" id="exportDeviceUsageReportBox">匯出為 Excel</div>
        `;
        this.box.set_btnwpr(boxBtn);

        // 實體化 comPick 公司選擇器
        this.durComPicker = new comPicker(
            this.managersMenu.data.coms,
            document.getElementById('durComPicker')
        )

        // 實體化 monthPicker 年月選擇器
        this.durMonthPicker = new monthPicker({
            container: document.getElementById('durMonthPicker'),
            // startYear: '2020', // TEST
            // endYear: '2024',   // TEST
            monthNames: window.langTxt.monthNames
        });

        // 內容區加入空白提示
        this.boxContent.innerHTML =
        `<div class="empty-hint">
            <div class="content">
                <h4>歡迎使用裝置月報表查詢</h4>
                <p>請選擇公司與年份、月份</p>
            </div>
        </div>`;

        document.body.classList.remove('loading');

        // TEST
        // this.box.open();
    }

    /* ----- 送出查詢 ----- */
    async submitDurQuery(){

        // console.log(this.durComPicker.pick);
        // console.log(this.durMonthPicker.pick);

        const comPickData = this.durComPicker.pick;
        const monthPickData = this.durMonthPicker.pick

        if(!comPickData.comId){
            showFeedbackMsg('尚未選擇公司', 'fail');
            return;
        }

        if(!monthPickData.year || !monthPickData.month){
            showFeedbackMsg('日期選擇錯誤', 'fail');
            return;
        }

        let setting = {};

        const comId = parseInt(comPickData.comId, 10);
        const comTypes = parseInt(comPickData.types, 10);

        if(comTypes === 2){
            // 經銷商
            setting.distributor_id = comId;
        } else {
            // 非經銷商
            setting.company_id = comId;
        }

        // 使用者選擇的年用，格式是字串 ex: 2023-06
        const monthPick = monthPickData.text;

        setting.report_month = monthPick;

        document.body.classList.add('loading');

        // TEST 須移除
        // setting.distributor_id = 153;
        // setting.report_month = '2022-05';

        let apiData = null;
        try {
            apiData = await sendApiRequest('post', '/coms/deviceUsageReport', setting)
                .then(res => res.data);

        } catch(error){
            globalErrorHandler(error);
            // ...其他錯誤處理程序
        }

        document.body.classList.remove('loading');

        this.showReportData(apiData.data, comPickData, monthPick);
    }

    /* ----- 顯示查詢到的報表資料 ----- */
    // 摘要 Data.summary
    // 資料 Data.records
    // monthPick: 使用者選擇的年用，格式是字串 ex: 2023-06
    showReportData(Data, comPick, monthPick){

        // console.log(Data);

        const boxDom = this.box.container;

        // 清空要匯出的資料
        this.reportData = {};

        // 沒有資料
        if(Data.summary.length < 1 || Data.records.length < 1){
            const emptyHint =
            `<div class="empty-hint">
                <div class="content">
                    <h4>無報表資料</h4>
                    <p>${comPick.name} / ${monthPick}</p>
                </div>
            </div>`;

            boxDom.classList.add('no-data');
            this.boxContent.innerHTML = emptyHint;

            return;
        }

        /* ----- 顯示摘要 ----- */
        // 1. 整理資料
        // 每個裝置群組中，不同狀態的統計數量 (通用)
        const _count = {
            billed: 0, // 啟用
            enabled: 0, // 未啟用
            disabled: 0, // 停用
            spare: 0, // 備品
            sample: 0, // 樣品
            deleted: 0 // 已回收
        }
        // 顯示的順序以及用來篩選的 key 值
        const deviceSumData = [
            {
                // 第一筆是全部裝置
                group: 'All',
                key: 'all_device',
                count: {..._count}
            },
            {
                // PAB 無線，型號為 pab 開頭，以下類推
                group: 'PAB wireless',
                key: 'pab',
                count: {..._count}
            },
            {
                group: 'CAB wired',
                key: 'cab',
                count: {..._count}
            },
            {
                group: 'ALC10B wireless',
                key: 'alc10b',
                count: {..._count}
            },
            {
                group: 'ALC10C wired',
                key: 'alc10c',
                count: {..._count}
            },
            {
                group: 'ALG wireless',
                key: 'alg',
                count: {..._count}
            },
            {
                group: 'Blood pressure',
                key: 'db',
                count: {..._count}
            },
            {
                // 最後一筆是不明裝置
                group: 'Others',
                key: 'unknown_device',
                count: {..._count}
            }
        ];

        for(const key in Data.summary){
            /*
            * key 是裝置型號，例如 CAB2001S
            * Data.summary[key] 是 server 計算此裝置的數據，資料範例如下：
            "CAB2001S": {
                "sample": 0,
                "spare": 0,
                "billed": 55,
                "enabled": 0,
                "disabled": 0,
                "deleted": 0
            },
            */
            const deviceName = key.toLowerCase(); // 轉換為小寫用來比對
            const sum = Data.summary[key];

            // 檢查裝置型號是否符合，如果都不符合的話表示是不明裝置
            let deviceNameValid = false;

            deviceSumData.forEach((deviceSumDataItem) => {

                const filterKey = deviceSumDataItem.key;

                if(filterKey === 'all_device'){
                    // 不論裝置類別，都要加入到 All 的統計中
                    for(const key in sum){
                        deviceSumDataItem.count[key] += sum[key];
                    }

                } else {
                    // regExp 規則：關鍵字之後接任意數量的非空白字元，不區分大小寫
                    const re = new RegExp(`^${filterKey}\\S+`, 'i');

                    // 如果型號符合關鍵字，則加入到此項目的統計數量中
                    if(re.test(deviceName)){
                        deviceNameValid = true;
                        for(const key in sum){
                            deviceSumDataItem.count[key] += sum[key];
                        }
                    }
                }
            });

            // 都不符合，加入到不明裝置的統計數量中
            if(!deviceNameValid){
                for(const key in sum){
                    // 最後一筆是不明裝置
                    deviceSumData[deviceSumData.length - 1]['count'][key] += sum[key];
                }
            }

        }

        // 2. 從資料找取一筆當範例，找出報表起迄日期 report_start / report_end
        const sampleData = Data.records[0];

        // 報表起訖時間
        const startDate = sampleData.report_start;
        const endDate = sampleData.report_end;

        let deviceResult = '';

        let slotCounts = 0; // 計算 slot 的數量，最後要保持雙數

        const fieldName = this.uiTxt;

        // 裝置狀態的顯示順序
        // --> 啟用 / 未啟用 / 停用 / 備品 / 樣品 / 已回收
        const _cellOrder = [
            'billed', 'enabled', 'disabled', 'spare', 'sample', 'deleted',
        ]

        deviceSumData.forEach((item) => {
            // 如果 item.count 中的數量都是 0 則跳過這一項
            const itemCount = item.count;
            let total = 0;
            for(const key in itemCount){
                total += itemCount[key];
            }
            if(total >= 1){

                let cells = '';

                _cellOrder.forEach((key) => {
                    const empty = (itemCount[key] < 1)? ' empty' : '';
                    cells +=
                    `<div class="cell ${key}${empty}">${fieldName[key]}：${itemCount[key]}</div>`
                });


                deviceResult +=
                `<div class="slot ${item.key}">
                    <div class="cell group">${item.group} (${total})</div>
                    ${cells}
                </div>`;

                slotCounts += 1;
            }
        });

        if(slotCounts % 2 !== 0){
            deviceResult += '<div class="slot spacer"></div>';
        }

        const lang_pageComs = window.langTxt.pageComs;

        // 顯示在畫面上的報表起訖日
        const reportDate =
        `<div class="date">${this.dateTimeHtml(startDate)} ~ ${this.dateTimeHtml(endDate)}</div>`;

        const sumPanel =
        `<div class="sumPanel">
            <div class="info">
                <div class="group">
                    <div class="tag comid"># ${comPick.comId}</div>
                    <div class="tag type">${lang_pageComs.types[comPick.types]}</div>
                    <h4>${comPick.name}</h4>
                </div>
                ${reportDate}
            </div>

            <div class="device-sum-panel">${deviceResult}</div>
        </div>`;

        // 儲存 summary 資料，需額外紀錄公司名稱與報表起訖日
        this.reportData.summary = deviceSumData;
        this.reportData.companyName = comPick.name;
        this.reportData.startDate = parseDatetime(startDate, true);
        this.reportData.endDate = parseDatetime(endDate, true);

        /* ----- 顯示報表內容 ----- */
        const reportPanel =
        `<div class="reportPanel">
            ${this.tableContent(Data.records)}
        </div>`;

        boxDom.classList.remove('no-data');
        this.boxContent.innerHTML = `${sumPanel}${reportPanel}`;

        // console.log(deviceSumData);
    }

    // ----- 顯示在畫面上的日期時間資料，時間用較小的文字顯示 ----- //
    dateTimeHtml(data){

        const datetime = parseDatetime(data);

        if(!datetime){
            return '';
        }

        return `${datetime.date.display} <small>${datetime.time}</small>`;
    }

    /* ----- 報表內容 HTML ----- */
    tableContent(Data){
        // 資料排序規則：公司 -> 型號 -> 量測次數，由多到少排序
        const orderedData = _orderBy(
            Data, ['company_id', 'model_name', 'test_count'], ['desc', 'asc', 'desc']
        )

        // 儲存 records 資料
        this.reportData.records = orderedData;

        // 欄位名稱
        const fieldName = this.uiTxt;

         let tableHead = ''; // table head

         let tableBody = ''; // table body

         tableMap.forEach((mapItem) => {

            let pass = true;

            // 根據權限設定是否要顯示欄位
            if(mapItem.hasOwnProperty('permission')){
                const permission = mapItem.permission;
                if(!permission.includes(this.roleCode)){
                    pass = false;
                }
            }

            if(pass){
                const key = mapItem.key;
                // 欄位名稱
                tableHead += `<th class="${key}">${fieldName[key]}</th>`;
            }
         });

         orderedData.forEach((dataItem) => {
            tableBody += `<tr class="status-${dataItem.device_status}">`;
            // tableBody += `<tr class="status--2">`;

            tableMap.forEach((mapItem) => {

                let pass = true;

                // 根據權限設定是否要顯示欄位
                if(mapItem.hasOwnProperty('permission')){
                    const permission = mapItem.permission;
                    if(!permission.includes(this.roleCode)){
                        pass = false;
                    }
                }

                if(pass){
                    const key = mapItem.key;
                    const output = this.preprocess(key, dataItem);

                    let content = '';
                    if(key === 'device_status' && output.length > 0){
                        // 裝置狀態
                        content = `<span class="status">${output}</span>`;
                    } else {
                        content = output;
                    }

                    // 表格資料
                    tableBody += `<td class="${key}">${content}</td>`;
                }
             });

            tableBody += '</tr>';
         });

        const html =
        `<table>
            <thead><tr>${tableHead}</tr></thead>
            <tbody>${tableBody}</tbody>
        </table>`;

        return html;
    }

    /* ----- 欄位資料處理 ----- */
    preprocess(key, dataItem, exportData = false){

        // exportData: 是否為匯出，匯出的時候不包含 html 標籤

        let output = dataItem[key]; // 資料原始值

        // 空值
        if(
            dataItem[key] === null || dataItem[key] === undefined ||
            dataItem[key] === 'null' ||
            dataItem[key] === ''
        ){
            if(key === 'test_count'){
                // 量測次數如果沒資料的話顯示為 0
                if(exportData){
                    output = '0';
                } else {
                    output = '<span class="fade">0</span>';
                }

            } else {
                output = '';
            }
        }

        // 累計量測次數
        if(key === 'device_count_total' && output === 0){
            if(exportData){
                output = '0';
            } else {
                output = '<span class="fade">0</span>';
            }
        }

        // 裝置狀態 (device_status)，等同於 device 的 active 值
        if(key === 'device_status'){
            if(this.lang_active[output]){
                output = this.lang_active[output];
            }
        }

        // 查詢裝置轉移紀錄
        // 使用序號查詢，如果序號是空的或 PunchIn，則按鈕為 disabled 狀態
        if(key === 'transfer_log'){

            let sn = dataItem.serial_no;
            let btnClass = '';

            if(!sn || sn.toLowerCase() === 'punchin' || sn.length < 1){
                sn = 'disabled';
                btnClass = ' disabled';
            }

            const model = (dataItem.model_name)? dataItem.model_name : 'Unknown';

            output =
            `<div class="btn btn-translog${btnClass}" data-sn="${sn}" data-model="${model}">查詢</div>`;
        }

        // 日期相關欄位
        if(
            key === 'earliest_time' || key === 'latest_time' ||
            key === 'checkin_time' || key === 'last_updated'
        ){

            let result = '';

            const datetime = parseDatetime(output);

            if(datetime){
                if(exportData){
                    // 匯出資料
                    result = datetime.output;
                } else {
                    // 畫面顯示
                    result =
                    `<span class="date d1">${datetime.date.display}</span>
                    <span class="date d2">${datetime.time}</span>`;
                }
            }
            return result;
        }

        return output;
    }

    /* ----- 匯出報表資料 ----- */
    exportReportData(){
        /*
        數量統計的資料儲存在 this.reportData.summary
        報表的資料儲存在 this.reportData.records
        需要準備：
            1. sheetName: 在 xlsx 中要產生的 sheet 名稱
            2. sheetHeader: 欄位名稱與顯示順序
            3. sheetData: 資料
        */

        const uiText =  this.uiTxt;

        if(!this.reportData.summary){
            showFeedbackMsg(uiText.message.noData, 'fail');
            return;
        }

        // 顯示載入中提示，這個提示會在 openDownloadDialog 中移除
        document.body.classList.add('loading');

        // 一個 xlsx 檔中可生成多個 sheet，在此指定各個 sheet 的名稱 (相當於資料分頁)
        const sheetName = [
            'Summary',
            'All records', 'PAB wireless', 'CAB wired',
            'ALC10B wireless', 'ALC10C wired', 'Blood pressure'
        ];

        // sheetHeader 指定需要顯示的欄位以及顯示順序
        // 範例格式如下 (相當於各欄位的 th 名稱和顯示順序 )
        const thReference = uiText.thReference;
        /*
        const sheetHeader = {
            'Summary': [
                '類別', '啟用', '未啟用', '停用', '備品', '樣品', '已回收',
                '報表起算日', '報表結束日'
            ],
            'All records': [
                '經銷商', '公司', '型號', '序號', '量測次數', '累計量測次數',
                '最初量測時間', '最後量測時間', 'Check-in 時間', '最後更新時間'
            ],
            'PAB wireless': [
                '經銷商', '公司', '型號', '序號', '量測次數', '累計量測次數',
                '最初量測時間', '最後量測時間', 'Check-in 時間', '最後更新時間'
            ],
            ...
        }
        */
        const sheetHeader = {};
        sheetName.forEach((name) => {
            if(name === 'Summary'){
                sheetHeader[name] = thReference.summary;
            } else {
                sheetHeader[name] = thReference.record;
            }
        });

        // sheetData 報表資料，需整理成以下範例格式
        // sheet 的 key 值對應 sheetName
        // 個別資料物件中的 key 值對應 sheetHeader 中的欄位名稱
        /*
        {
            'Summary': [{
                類別: 'PAB wireless',
                啟用: 10,
                未啟用: 1,
                ...
            }, ...],
            'All records': [{
                經銷商: 'xxx',
                公司: 'xxx',
                型號: 'xxx',
                序號: 'xxx',
                ...
            }, ...]
        }
        */
        // 此處先根據 sheetName 來產生空白的 sheetData
        const sheetData = {};
        sheetName.forEach((item) => {
            sheetData[item] = [];
        });

        // 除了 Summary 之外，其他的報表資料需要經過篩選
        // 在此設定用來篩選資料的關鍵字 (String)，不區分大小寫
        // 根據序號開頭的字串來進行資料篩選
        const dataFilter = {
            'All records': null, // null 表示不篩選
            'PAB wireless': 'pab',
            'CAB wired': 'cab',
            'ALC10B wireless': 'alc10b',
            'ALC10C wired': 'alc10c',
            'Blood pressure': 'db'
        }

        // Step 1. sheetData.Summary 是例外，需要單獨整理
        this.reportData.summary.forEach((item) => {
            /*
            // 資料範例
            {
                "group": "PAB wireless",
                "key": "pab",
                "count": {
                    "billed": 13,
                    "enabled": 0,
                    "disabled": 0,
                    "spare": 0,
                    "sample": 0,
                    "deleted": 0
                }
            }
            */

            // 跳過總數量是 0 的裝置類型
            let total = 0;
            for(const key in item.count){
                total += item.count[key];
            }
            if(total > 0){
                // 只需要 group 與 count，並將 count 展開
                const data = {
                    group: item.group,
                    ...item.count
                }

                const output = {};

                for(const key in data){
                    const fieldName = uiText[key];
                    output[fieldName] = data[key];
                }

                output['報表起算日'] = this.reportData.startDate;
                output['報表結束日'] = this.reportData.endDate;

                sheetData.Summary.push(output);
            }
        });

        // Step 2. 整理 Summary 以外的其他資料
        const rawData = this.reportData.records;
        for(const key in dataFilter){

            let data = [];
            if(dataFilter[key]){

                // 用來篩選的關鍵字，不區分大小寫
                const filterKey = dataFilter[key];
                // 關鍵字之後接任意數量的非空白字元，不區分大小寫
                const re = new RegExp(`^${filterKey}\\S+`, 'i');

                rawData.forEach((item) => {
                    if(re.test(item.serial_no)){
                        data.push(item);
                    } else if(re.test(item.model_name)){
                        // 作為備案，也檢查 model_name，例如血壓計的序號就是例外
                        data.push(item);
                    }
                });
            } else {
                data = [...rawData];
            }

            if(data.length < 1){
                // 如果是空的資料，不存入 sheetData 並刪除 sheetName 中對應的 key 值
                const index = sheetName.indexOf(key);
                sheetName.splice(index, 1);

            } else {

                // 資料排序規則：公司 -> 型號 -> 量測次數，由多到少排序
                const orderedData = _orderBy(
                    data, ['company_id', 'model_name', 'test_count'], ['desc', 'asc', 'desc']
                );

                // 整理為 sheetData[key] 需要的格式
                const thHeader = sheetHeader[key];

                const outputData = [];

                orderedData.forEach((item) => {
                    const entry = {};
                    for(const key in item){
                        // 中文欄位名稱
                        const fieldName = uiText[key];
                        // 只取需要的欄位資料 (只有在 sheetHeader 中列出的項目)
                        if(thHeader.includes(fieldName)){
                            entry[fieldName] = `${this.preprocess(key, item, true)}`;
                        }
                    }
                    outputData.push(entry);
                });

                sheetData[key] = outputData;
            }

        }

        const fileName =
        `${this.reportData.companyName}-${this.reportData.startDate.slice(0,10)}-${this.reportData.endDate.slice(0,10)}`;

        exportXlsx(sheetName, sheetData, sheetHeader, fileName);
    }

    /* ----- 查詢裝置轉移紀錄 ----- */
    // SN: 裝置序號
    async queryTransferLog(SN, ModelName){

        if(SN === 'disabled' || SN.length < 1){
            return;
        }

        document.body.classList.add('loading');

        let apiData = null;
        try {
            apiData = await sendApiRequest('post', '/devices/log', {
                serial_no: SN
            })
                .then(res => res.data);

        } catch(error){
            globalErrorHandler(error);
            // ...其他錯誤處理程序
        }

        this.dtlBox.set_title(
            `Model: ${ModelName}, SN: ${SN}`
        );

        // 檢查資料內容
        const Data = apiData.data;
        if(!Data || Data.length < 1 || !Array.isArray(Data)){
            this.dtlBox.set_noData();
        } else {
            this.dtlBox.set_content(
                this.transferLogTable(Data)
            );
        }

        document.body.classList.remove('loading');

        this.dtlBox.open();
    }


    /* ----- 產生裝置轉移紀錄資料表格 HTML ----- */
    transferLogTable(Data){

        // console.log(Data);

        // 欄位順序
        const dataOrder = [
            'company_sname', 'site_sname',
            'active', 'timestamp', 'initial_test_count'
        ]

        // 欄位名稱
        const colName = this.uiTxt.transferLog;

        // 裝置狀態名詞對照
        const parseActive = this.lang_active;

        let tableHead = '';
        let tableBody = '';

        function parseData(dataItem, key){
            let output = dataItem[key];
            if(key === 'active' && parseActive[output]){
                const text = parseActive[output];
                if(output === -2){
                    output = `<span class="disabled">${text}</span>`;
                } else {
                    output = text;
                }
            }
            return output;
        }

        Data.forEach((dataItem, index) => {

            tableBody += `<tr>`;

            dataOrder.forEach((key) => {
                // 藉由第一筆建立 table header
                if(index === 0){
                    // console.log(index);
                    tableHead += `<th class="${key}">${colName[key]}</th>`;
                }

                tableBody += `<td class="${key}">${parseData(dataItem, key)}</td>`;
            });

            tableBody += `</tr>`;
        });

        const Html =
        `<table>
            <thead><tr>${tableHead}</tr></thead>
            <tbody>${tableBody}</tbody>
        </table>`;

        return Html;
    }

}