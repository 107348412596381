import '../css/common.less';
import '../css/panel-common.less';
import '../css/custom.less';

import {
    createContainer,
    unauthFooter
} from './helper/template'; // 版型

/* ----- axios get data ----- */
import {
    getAnnouncement,
    sendApiRequest,
    globalErrorHandler,
    getManagersMenu
} from './api';

// top header
import {Header} from '../components/header/header';

// 使用者資料、使用者選單
import {User} from '../components/user/user';

// 批次停用酒測器 (cem only)
// import {DeviceDeBatch} from '../components/deviceDebatch/deviceDebatch';

// dialog box，用來顯示 API 錯誤訊息
import {dialogBox} from '../components/dialogBox/dialogBox';

// 裝置月報表
import {deviceUsageReport} from '../components/deviceUsageReport/deviceUsageReport';

// 授權碼報表
import {licenseUsageReport} from '../components/licenseUsageReport/licenseUsageReport';

// 庫存警示
import {inventoryAlert} from '../components/inventoryAlert/inventoryAlert';

const dayjs = require('dayjs');
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

/* ----- Start ----- */
async function start(){

    // 決定區域代碼、顯示語言、客製化樣式
    environment();

    // 根據 window.page 變數值來判斷要顯示頁面內容
    // 如果此值不存在，就指定為 basic 基本版型 (例如 terms, rules 等純文字頁面）
    const currPage = (window.page)? window.page : 'basic';

    // 在 body 加上 'window.page' className
    document.body.classList.add(currPage);

    // 建立主 header (header.header 是由 blade 所建)
    let landing = false;
    switch(currPage){
        case 'landing':
        case 'checklist':
        case 'manual':
            landing = true;
            break;
    }
    const mainHeader = new Header(
        document.querySelector('header.header'),
        landing
    );

    // 取得系統公告內容
    let announceData = null;
    try {
        announceData = await getAnnouncement().then(res => res.data);
    } catch(error){
        console.log(error);
    }
    showAnnouncement(announceData);

    // 適用所有頁面的 event handler
    globalEvents();

    // dialog box，用來顯示 API 錯誤訊息
    window.apiErrBox = new dialogBox('apiErrBox', false, false);
    apiErrBoxInit();

    // message box，只有關閉按鈕，用來顯示比較單純的訊息
    window.msgBox = new dialogBox('msgBox', false, false);
    msgBoxInit();

    // 根據目前頁面，執行相對應的動作
    showContent(currPage);
}

start();
// if(window.env === 'dev'){
//     timeCheckTest();
// }

// ----- 適用所有頁面的 event handler ----- //
function globalEvents(){

    document.body.addEventListener('change', (event) => {
        // 移除 text input 前後空白
        if(event.target.tagName === 'INPUT'){
            const target = event.target;
            if(target.type === 'text' || target.type === 'email'){
                target.value = target.value.trim();
            }
        }

    });

}

/* ---------- 取得一些初始資料後，顯示各頁面內容 ---------- */
async function showContent(page){

    // 設定基本的 app 下載按鈕連結，提供未登入時使用
    // 登入後改為 js 控制按鈕與選單
    // const btnAppDwn = document.querySelector('#btnAppDwn');
    // if(btnAppDwn){
    //     btnAppDwn.href = window.langTxt.appDownload.regular.link;
    //     btnAppDwn.setAttribute('target', '_blank');
    // }

    document.body.classList.add('loading');

    // 取得使用者登入資訊
    let userInfoData = null;
    try {
        userInfoData = await sendApiRequest('post', '/user/info', null)
            .then(res => res.data);

    } catch(error){
        globalErrorHandler(error);
    }
    // 未登入
    // authcheck: false ​​
    // user: null

    // 已登入
    // authcheck: true
    // jwt: JWT token
    // user: 使用者資訊，包含綁定的管理權限等

    // 用來儲存實體化 User 物件後整理的使用者資料
    let userRoleInfo = null;

    if(!userInfoData.data.authcheck){
        // ----- 使用者未登入 ----- //
        switch(page){
            // 說明文件或靜態文字頁
            case 'document':
            case 'basic':
            // 註冊登入等相關頁面
            case 'login':
            case 'signup':
            case 'forgotpw':
            case 'resetpw':
            // 登入與否都可以看到的獨立內容頁
            case 'landing':
            case 'checklist':
            case 'manual':
                // do nothing
                break;

            default:
                // 其他頁面都需要登入後才能看到內容，所以必須直接顯示 login 畫面
                // 移除原本的 page class
                document.body.classList.remove(page);
                // 顯示 login 畫面
                page = 'login';
                // 將網址替換為 login
                window.history.replaceState(null, null, '/login');
                document.body.classList.add(page);
        }

    } else {
        // ----- 使用者登入後 ----- //

        /* --- 實體化 User 物件 --- */
        window.mainUser = new User(userInfoData);

        // 整理後的使用者登入狀態與資訊
        userRoleInfo = mainUser.userRoleInfo;

        // 若使用者已經登入但尚未綁定任何權限，顯示 user-match 頁的內容
        if(userRoleInfo.allRoles === null){
            if(window.env === 'dev'){
                console.log('未綁定任何權限，顯示 user-match 頁');
            }
            // 移除原本的 page class
            document.body.classList.remove(page);
            page = 'user-match';
            // 將網址替換為 user/match
            window.history.replaceState(null, null, '/user/match');
            document.body.classList.add(page);
        } else {
            // 如果使用者有權限，但誤入 /user/match 頁
            if(window.page === 'user-match'){
                // 強制轉換到首頁
                location.replace('/');
            }
        }

        // 先取得一次 managers/menu 資料 (儲存到 window 變數中)
        // 必須在 User 實體之後，因為需要根據不同權限取得對應的資料
        window.managersMenuData = await getManagersMenu();
    }

    const roleCode = (userRoleInfo && userRoleInfo.role)? userRoleInfo.role : null;
    const roleId = (userRoleInfo && userRoleInfo.id)? userRoleInfo.id : null;

    // 顯應頁面對應的內容
    switch (page) {

        case 'basic':
            unauthFooter(); // 未登入時的 footer
            break;

        // 登入頁
        case 'login':
            document.body.classList.add('unauth');
            import(/* webpackChunkName: "signup" */ '../components/account/login').then((module) => {
                const Module = module.default;
                const loginModal = new Module();
                unauthFooter(); // 未登入時的 footer
            });
            break;

        // 註冊頁
        case 'signup':
            document.body.classList.add('unauth');
            import(/* webpackChunkName: "signup" */ '../components/account/signup').then((module) => {
                const SignupModal = module.default;
                const signupModal = new SignupModal();
                unauthFooter(); // 未登入時的 footer
            });
            break;

        // 忘記密碼
        case 'forgotpw':
            document.body.classList.add('unauth');
            import(/* webpackChunkName: "forgotpw" */ '../components/account/forgotpw').then((module) => {
                const ForgotPwModal = module.default;
                const forgotPwModal = new ForgotPwModal();
                unauthFooter(); // 未登入時的 footer
            });
            break;

        // 重設密碼
        case 'resetpw':
            document.body.classList.add('unauth');
            import(/* webpackChunkName: "resetpw" */ '../components/account/resetpw').then((module) => {
                const ResetPwModal = module.default;
                const resetPwModal = new ResetPwModal();
                unauthFooter(); // 未登入時的 footer
            });
            break;

        // 權限綁定
        case 'user-match':
            import(/* webpackChunkName: "resetpw" */ '../components/user/match').then((module) => {
                const UserMatch = module.default;
                const userMatch = new UserMatch();
                unauthFooter(); // 未登入時的 footer
            });
            break;

        // 舊的資訊統計頁 (裝置狀態總覽)
        case 'panel-info':
            createContainer(roleCode, roleId);
            import(/* webpackChunkName: "info" */ '../pages/info/info').then((module) => {
                const Info = module.default;
                const panelInfo = new Info(roleCode, roleId);
            });
            break;

        // 公司列表
        case 'panel-coms':
            createContainer(roleCode, roleId);
            import(/* webpackChunkName: "coms" */ '../pages/coms/coms').then((module) => {
                const Coms = module.default;
                const panelComs = new Coms(roleCode, roleId);
            });
            break;

        // 站點列表
        case 'panel-sites':
            createContainer(roleCode, roleId);
            import(/* webpackChunkName: "sites" */ '../pages/sites/sites').then((module) => {
                const Sites = module.default;
                const panelSites = new Sites(roleCode, roleId);
            });
            break;

        // APP 用戶端
        case 'panel-edges':
            createContainer(roleCode, roleId);
            import(/* webpackChunkName: "edges" */ '../pages/edges/edges').then((module) => {
                const Edges = module.default;
                const panelEdges = new Edges('edges', roleCode, roleId);
            });
            break;

        // 裝置管理
        case 'panel-devices':
            createContainer(roleCode, roleId);
            import(/* webpackChunkName: "devices" */ '../pages/devices/devices').then((module) => {
                const Devices = module.default;
                const panelDevices = new Devices('devices', roleCode, roleId, {
                    exportData: true
                });
            });
            break;

        // 管理者列表
        case 'panel-managers':
            createContainer(roleCode, roleId);
            import(/* webpackChunkName: "managers" */ '../pages/managers/managers').then((module) => {
                const Managers = module.default;
                const panelManagers = new Managers('managers', roleCode, roleId, {
                    exportData: true
                });
            });
            break;

        // 受測者列表
        case 'panel-testers':
            createContainer(roleCode, roleId);
            import(/* webpackChunkName: "testers" */ '../pages/testers/testers').then((module) => {
                const Testers = module.default;
                const panelTesters = new Testers('testers', roleCode, roleId, {
                    exportData: true
                });
            });
            break;

        // 檢核表
        case 'panel-inspection':
            createContainer(roleCode, roleId);
            import(/* webpackChunkName: "inspection" */ '../pages/inspection/inspection').then((module) => {
                const Module = module.default;
                const panel = new Module(roleCode, roleId);
            });
            break;

        // 紀錄查詢
        case 'panel-records':
            createContainer(roleCode, roleId);
            import(/* webpackChunkName: "records" */ '../pages/records/records').then((module) => {
                const Records = module.default;
                const panelRecords = new Records('records', roleCode, roleId, {
                    fetchListData: false
                });
            });
            break;

        // dashboard prototype
        // case 'dashboard-proto':
        //     createContainer(roleCode, roleId);
        //     import(/* webpackChunkName: "dashboard" */ '../pages/dashboard-proto/dashboard').then((module) => {
        //         const Dashboard = module.default;
        //         const page = new Dashboard(roleCode, roleId);
        //     });
        //     break;

        // dashboard (正式版)
        case 'dashboard':
            createContainer(roleCode, roleId);
            import(/* webpackChunkName: "dashboard" */ '../pages/dashboard/dashboard').then((module) => {
                const Dashboard = module.default;
                const page = new Dashboard(roleCode, roleId);
            });
            break;

        /* ----- 以下為獨立的頁面，版型跟其他頁面不同 ----- */
        // landing page
        case 'landing':
            import(/* webpackChunkName: "landing" */ './landing').then((module) => {
                const init = module.default;
                init();
            });
            break;

        // 檢核表
        case 'checklist':
            import(/* webpackChunkName: "checklist" */ '../checklist/checklist').then((module) => {
                // 獨立的頁面都套用 landing 的基本樣式
                document.body.classList.add('landing');
                const Module = module.default;
                const checklist = new Module();
            });
            break;

        // 站點使用須知
        case 'manual':
            import(/* webpackChunkName: "manual" */ '../pages/manual/manual').then((module) => {
                // 獨立的頁面都套用 landing 的基本樣式
                document.body.classList.add('landing');
                const Module = module.default;
                const Manual = new Module();
            });
            break;

        // 跨公司查詢的範例頁面 (for 奇美)
        // case 'chimei':
        // import(/* webpackChunkName: "chimei" */ '../pages/chimei/chimei').then((module) => {
        //     const Module = module.default;
        //     const chimei = new Module();
        // });
        // break;
    }

    // 以下放在 header 的獨立功能按鈕，用 appendChild 的方式插入
    // 所以會按照執行的先後順序，由左到右排列
    const extraBtnWpr = document.getElementById('extraBtnWpr');

    if(roleCode === 'cem' || roleCode === 'dm'){
        // 裝置月報表 device usage report
        const deviceUsageReportModal = new deviceUsageReport(
            extraBtnWpr, userRoleInfo.role
        );
        // 授權碼報表 license usage report
        const LURModal = new licenseUsageReport(
            extraBtnWpr, userRoleInfo.role
        );
    }

    if(roleCode === 'cem'){
        // 批次停用酒測器
        // const deviceDeBatchBox = new DeviceDeBatch(
        //     userRoleInfo.id, extraBtnWpr
        // );
        // 庫存警示
        window.inventoryAlertBox = new inventoryAlert(extraBtnWpr);
    }

    document.body.classList.remove('loading');
}

/* ----- 顯示系統公告 ----- */
function showAnnouncement(Data){

    if(Data.visibility !== 'visible'){
        return;
    }

    // 公告日期
    const annoDate = dayjs(Data.date, ['YYYY-MM-DD', 'YYYY-M-D']);
    // 公告日期加 1 天
    const endDate = annoDate.add(1, 'day');

    const today = dayjs();

    if(today.isAfter(endDate, 'day')){
        // 已經超過公告日期，後續程序不執行
        return;
    }

    const Html =
    `<div class="icon"><svg>
        <use xlink:href="/assets/sprites/sprites_solid.svg#info"></use>
    </svg></div>
    <p>${Data.content}</p>`;

    const container = document.getElementById('sysAnnowpr');
    if(container){
        container.innerHTML = Html;
    }

}

/* ----- apiErrBox event handler ----- */
function apiErrBoxInit(){

    const apiErrBox = document.getElementById('apiErrBox');

    apiErrBox.addEventListener('click', (event) => {

        if(event.target.id === 'apiErrBoxcancel'){
            window.apiErrBox.close();
        }

        if(event.target.id === 'apiErrBoxconfirm'){
            location.reload();
        }

    });

}

/* ----- message box，只有關閉按鈕，單純顯示訊息 ----- */
function msgBoxInit(){

    const boxTxt = window.langTxt.messageBox;

    const box = document.getElementById('msgBox');

    window.msgBox.set_btnwpr(
        `<div class="btn close" id="msgBoxclose">
            ${boxTxt.btn.close}
        </div>`
    )

    box.addEventListener('click', (event) => {
        if(event.target.id === 'msgBoxclose'){
            window.msgBox.close(); // 預設關閉時會清除訊息內容
        }
    });

}

// ----- 決定區域代碼、顯示語言、客製化樣式 ----- //
function environment(){
    // 以下三組為互相獨立的設定，彼此不互相影響
    // 顯示語言、客製化樣式、區域代碼
    // 會將值儲存在以下 window 變數中
    // window.currLang (顯示語言) tc || jp || en
    // window.countryCode (區域代碼) TW || JP
    // window.langTxt (語言檔)
    // window.env (只有在測試環境會等於 'dev'，其它環境都是 undefined)
    // window.page (目前要顯示的頁面內容，這個值需要在 blade 中提前指定)

    const body = document.body;
    const host = window.location.hostname;
    const agent = window.navigator.userAgent;

    // TEST 測試不同的 hostname
    // const host = 'taiwan.alcolog.io';
    // const host = 'alcohol.careexpert.life';
    // const host = 'alc-ctrl.acerits.com';
    // const host = 'alc.tripodworks.co.jp';
    // const host = 'japan.alcolog.io';
    // const host = 'japandev.alcolog.io';

    // 正式站 hostname 清單
    const PRODhost = [
        // 台灣
        'taiwan.alcolog.io',
        'alcohol.careexpert.life',
        'taiwan2.alcolog.io',
        't-cat.alcolog.io',
        // 客製化
        'alc-ctrl.acerits.com',
        'alc.tripodworks.co.jp',
        // 日本
        'japan.alcolog.io',
    ]

    // 測試站 hostname 清單
    const DEVhost = [
        'dev.alcolog.io',
        'devalcohol.careexpert.life',
        'japandev.alcolog.io',
    ]

    // TEST
    // localStorage.removeItem('user-lang');

    // 顯示語言
    // 使用者是否有選擇過語言，如果有的話就優先使用
    let currLang = localStorage.getItem('user-lang');
    // console.log(currLang);

    // 客製化樣式：根據 hostname 判斷是否使用客製化樣式
    let customSetting = null;

    // acerits
    if(host === 'alc-ctrl.acerits.com' || /\.acerits\./.test(host)){
        customSetting = 'acerits';
    }

    // tripodworks
    if(host === 'alc.tripodworks.co.jp' || /\.tripodworks\./.test(host)){
        customSetting = 'tripodworks';
        if(!currLang){
            // 如果使用者沒有設定過語言的話，tripodworks 預設顯示日文
            currLang = 'jp';
        }
    }

    if(customSetting){
        // 在 body 加上客製化樣式的 className，例如 body.acerits
        body.classList.add(customSetting);
        body.dataset.custom = customSetting;
    } else {
        // 預設樣式為 body.default
        body.classList.add('default');
        body.dataset.custom = 'default';
    }

    // 區域代碼，目前只有台灣跟日本，預設為 TW
    // 1. 顯示不同版本的 header logo
    // 2. 在語言檔中部分內容會按照區域代碼區分，例如 allSiteEnabled, whiteList 等
    // 注意區域代碼跟顯示語言是不同的設定
    // 客製化網址的 header logo 旁不顯示此代碼 (在 custom.less 中設定樣式)
    window.countryCode = 'TW';
    if(host === 'japan.alcolog.io' || /japan\w*\.alcolog/.test(host)){
        // 正式站 https://japan.alcolog.io
        // 測試站 https://japandev.alcolog.io
        window.countryCode = 'JP';
    }

    // Mobile 樣式 body.mobile
    if(agent.search(/mobile/gi) !== -1){
        body.classList.add('mobile');
    }

    // TEST
    // window.serverInfo = null;
    // window.serverInfo.app_env = 'production';
    // console.log(window.serverInfo);

    // 正式 / 測試環境
    let devEnv = false;
    // app_env 可能的值：
    // 正式環境：production
    // 開發環境：development / local / testing / staging
    // staging 視為開發環境，主要影響會是 dashboard api 的選擇，不需要佔用正式站資源
    try {
        switch(window.serverInfo.app_env){
            case 'production':
                // app_env 為正式環境時，再次確保不是測試站網址
                if(DEVhost.includes(host)){devEnv = true;}
                break;

            default:
                // app_env 為發開環境時，再次確保不是正式站網址
                if(!PRODhost.includes(host)){devEnv = true;}
        }
    } catch(error){
        console.log(error);
    }

    if(devEnv){
        body.classList.add('dev');
        body.dataset.env = 'dev';
        window.env = 'dev';
    }

    // 顯示語言
    if(!currLang){
        if(window.countryCode === 'JP'){
            // 日本網站
            currLang = 'jp';
        } else {
            // 預設顯示中文
            currLang = 'tc';
        }
    }

    body.dataset.lang = currLang;
    body.classList.add(currLang);

    // 將 currLang 值存入 window 變數中
    window.currLang = currLang;

    // 載入語言檔
    window.langTxt = require(`../lang/${currLang}.js`);
}

function timeCheckTest(){

    clearTimeout(window.testing);

    try {
        // 開始時間
        const startTime = dayjs();

        sendApiRequest('post', '/api/app/timecheck', null)
            .then(res => res.data)
            .then((data) => {
                // console.log(data.data.now);

                // 結束時間
                const endTime = dayjs();
                const diff = endTime.diff(startTime);
                console.log(diff);

                window.testing = setTimeout(() => {
                    timeCheckTest();
                }, 1000);

            });
    } catch(error){
        console.log(error);
    }



}
