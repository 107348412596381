import {showFeedbackMsg} from '../js/helper/feedbackMsg';

import axios from 'axios';
/*
使用範例
let apiData = null;
try {
    apiData = await sendApiRequest('post', '/managers/menu', null)
        .then(res => res.data);

} catch(error){
    globalErrorHandler(error);
    // ...其他錯誤處理程序
}
*/
export async function sendApiRequest(method, url, setting = null, token = null){

    // 若指定 token 參數 (帶入任意字串) 可模擬 token 過期的情況
    const xCsrfToken = token? token : document.querySelector('meta[name="csrf-token"]').content;

    let header = {
        'X-CSRF-TOKEN': xCsrfToken,
        'Content-Type': 'application/json'
    }

    if(token === 'none'){
        delete header['X-CSRF-TOKEN'];
    }

    // const server = '';
    // server 端需要允許 CORS
    // const server = await getSolServer();

    method = method.toLowerCase();

    return axios({
        headers: header,
        method: method,
        // url: `${server}${url}`,
        url: url,
        data: setting
      });
}

// ----- JWT token 請求 ----- //
export async function sendJWTRequest(token, method, url, data = null, signal = false){
    // signal: 可加入 signal 設定，目前用於 abortControl 取消請求

    const header = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }

    const setting = {
        headers: header,
        method: method.toLowerCase(),
        url: url
    }

    if(data){
        setting['data'] = data;
    }

    if(signal){
        setting['signal'] = signal;
    }

    return axios(setting);
}

// ----- 錯誤處理程序 ----- //
// error 是完整的錯誤訊息，錯誤代碼是 error.response.status
// 419: token 過期
export function globalErrorHandler(error){

    const apiErrBox = window.apiErrBox;

    const res = error.response;

    if(!res){
        return;
    }

    let statusText = '';
    let errMsg = '';

    if(res.statusText.length > 0){
        statusText = `<p>${res.statusText}</p>`;
    }

    if(res.data.message && res.data.message.length > 0){
        errMsg = `<p>${res.data.message}</p>`;
    }

    // api 錯誤訊息
    const apiErrTxt = window.langTxt.apiMsg.error.error1;

    const content =
    `<div class="error-msg">
        ${statusText}
        ${errMsg}
        <p>${apiErrTxt.tips}</p>
    </div>`;

    let title = apiErrTxt.msg;
    apiErrBox.set_title(title.replace('{variable}', res.status));

    apiErrBox.set_content(content);
    apiErrBox.open();
}

// ----- 針對 Azure API ----- //
export function sendAzureRequest(method, url, key, setting, type){

    method = method.toLowerCase();

    const headers = {
        'Content-Type': type,
        'Ocp-Apim-Subscription-Key': key // Azure API key
    }

    return axios({
        headers: headers,
        method: method,
        url: url,
        data: setting
      });
}

// ----- 取得 /managers/menu ----- //
export async function getManagersMenu(){

    // 優先使用 window 變數中現存的資料
    if(window.managersMenuData){
        return window.managersMenuData;
    }

    // crossRole: 是否取得跨權限資料 (all_credentials=1)
    let crossRole = true;

    // 根據 high role 決定是否取得跨權限資料
    try {
        const userInfo = window.mainUser.userRoleInfo;
        const highRoleCode = userInfo.highRoleCode;

        if(highRoleCode === 'cem' || highRoleCode === 'dm'){
            crossRole = false;
        }
    } catch(error){
        console.log(error);
    }

    try {
        let url = '/managers/menu';

        if(crossRole){
            url += '?all_credentials=1';
        }

        const apiData = await sendApiRequest('post', url, null)
            .then(res => res.data);

        if(apiData.ajaxcode === 0){
            // 將結果儲存到 window 變數
            window.managersMenuData = apiData;
            return apiData;
        } else {
            throw 'managers-menu error';
        }

    } catch(error){
        // globalErrorHandler(error);
        showFeedbackMsg('managers-menu error', 'fail');
        return null;
    }
}

// ----- 取得 /managers/menu 跨權限的資料 ----- //
// 取得跨權限的所有可見公司站點 (?all_credentials=1)
// 但是必須有公司管理或站點管理權限才列入計算
// 注意：目前只有在 dashboard 使用此種權限控管，為了不影響其他頁面，所以獨立一個 function
// 不能由 window.managersMenuData 中取得資料，也不能回存資料到此變數
/*
export async function getManagersMenuAll(){

    let result = null;

    try {
        // crossRoleTest 暫時只作用在 dev 環境
        // 如果是 com 或 sm，可直接使用 global 變數中的資料，不用重複取得
        // const roleCode = window.mainUser.userRoleInfo.role;
        // if(roleCode === 'com' || roleCode === 'sm'){
        //     if(window.env === 'dev' && window.managersMenuData){
        //         return window.managersMenuData;
        //     }
        // }

        // 以下為正常程序
        const method = 'post';
        const url = '/managers/menu?all_credentials=1';

        const apiData = await sendApiRequest(method, url, null)
            .then(res => res.data);

        if(apiData.ajaxcode === 0){
            result = apiData;
        }

    } catch(error){
        globalErrorHandler(error);
        // ...其他錯誤處理程序
    }

    return result;
}
*/

// ----- 取得公告內容 ----- //
export async function getAnnouncement(){
    return axios.get('/assets/json/announcement.json',{
        // 避免 cache
        params: {
          'timestamp': Date.now()
        },
      });
}


// ----- 取得伺服器列表清單，並返回指定的 Url ----- //
// 正式站 https://sol.careexpert.life/EndPoint
// 測試站 https://soldev.careexpert.life/EndPoint
// services: 'Web' (雲端平台) | 'Mgr' (Justus, Dashboard)
export async function getEndPoint(service){
    // TEST
    // window.serverInfo['app_env'] = 'staging';
    // window.serverInfo['app_env'] = 'production';
    // delete window.serverInfo['app_env'];
    // delete window.serverInfo['justus_api_url'];
    // delete window.env;

    // 如果無法取得 EndPoint 或發生任何錯誤時預設返回空字串
    // 這樣會以當前根目錄為入口
    let result = '';

    try {
        const appEnv = window.serverInfo['app_env'];
        const root = window.serverInfo['justus_api_url'];

        // staging 需要用正式站的 justus api
        // 所以此處要略過 staging，改用接下的程序判斷
        if(root && root.length > 0 && appEnv !== 'staging'){
            // 去掉末尾的 '/api'
            // 因為從 EndPoint 拿到的資料中，url 不包含 '/api'
            // 所以在這邊要維持一致的規則
            result = root.replace(/\/api\b/g, '');
        } else {
            // 無法從 serverInfo 中取值，改用 EndPoint API
            let subdomain = 'sol';

            if(window.env === 'dev' && appEnv !== 'staging'){
                subdomain = 'soldev';
            }

            const params = '?returnAll=true';
            const url = `https://${subdomain}.careexpert.life/EndPoint${params}`;

            // 取得的 endPointlist 資料結構參考
            // resources/layout/src/js/資料範例_EndPoint.js
            const endPointlist = await axios.get(url)
                .then((res) => {
                    return res.data;
                })
                .then((data) => {
                    if(data.status === 'success'){
                        return data.data;
                    } else {
                        throw 'EndPoint error';
                    }
                })
                .catch((error) => {
                    // console.log(error);
                    return null;
                });

            if(!Array.isArray(endPointlist)){
                throw 'EndPoint error';
            }

            // 取得目前網址，格式為 https://hostname
            const loc = window.location;
            let host = `${loc.protocol}//${loc.hostname}`;

            // TEST
            // host = 'https://taiwan.alcolog.io';
            // host = 'https://alcolog.careexpert.life';

            let hostAlias = null;

            // 因為無法確定 endPoint 中的正式站網址是新的還舊的
            // 所以在此處設定別名方便接下來的檢查程序
            switch(host){
                case 'https://taiwan.alcolog.io':
                    hostAlias = 'https://alcolog.careexpert.life';
                    break;

                case 'https://alcolog.careexpert.life':
                    hostAlias = 'https://taiwan.alcolog.io';
                    break;
            }

            let Data = null;

            endPointlist.forEach((data) => {
                // 只需要檢查其中的 services 資料
                data.services.forEach((item) => {
                    if(item['service'] === 'Web'){
                        // 比對 Web 服務的網址是否與 host / hostAlias 相同
                        if(item['url'] === host || item['url'] === hostAlias){
                            Data = data.services;
                        }
                    }
                });

            });

            if(Data){
                // 根據傳入的 service 值，找到對應的 url
                const target = Data.find((item) => {
                    return item['service'] === service;
                });
                result = target['url'];
            }
        }

    } catch(error){
        console.log(error);
    } finally {
        if(window.env === 'dev' && result.length > 0){
            console.log(result);
        }
        return result;
    }

}


// ----- 根據 user email 取的 server domain name ----- //
// 目前不需要此程序，改用 getEndPoint 取得伺服器列表清單
export async function getSolServer(email = null){

    let server = null;

    const localStorageData = localStorage.getItem('solserver');

    // console.log(window.solserver);
    // console.log(localStorageData);

    // 先檢查 window 或 localStorage 有沒有已存資料
    // 優先使用 window.solserver
    if(window.solserver){
        server = window.solserver;
    } else if(localStorageData){
        server = localStorageData;
    }

    if(!server){

        let userEmail = null;

        if(email){
            userEmail = email;
        } else if(window.mainUser){
            const userData = window.mainUser.rawData;
            const mail = userData.user.email;
            if(mail && mail.length > 0){
                userEmail = mail;
            }
        }

        if(userEmail){
            const url =
            `https://soldev.careexpert.life/Find/User/${userEmail}`;

            server = await axios.get(url)
                .then((res) => {
                    return res.data;
                })
                .then((data) => {
                    if(data.status === 'success'){
                        return data.data.Web;
                    } else {
                        return null;
                    }
                });

            if(server){
                window.solserver = server;
                localStorage.setItem('solserver', server);
            }
        }
    }
    // 如果沒有 server 資料的話返回空字串
    return (server)? server : '';
}